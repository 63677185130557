import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Relatos } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import FotosYCarrousel from '../components/fotos-y-carrousel'
import styled from '@emotion/styled'
import Navegar from '../components/navegar'

const TituloDelCarrousel = styled.div`
  width: 90%;
  margin: 4rem auto;
  color: white;
  font-family: Satisfy;
  font-size: 48px;
  text-shadow: 1px 1px 2px #16455a;
`
const EnvoltorioDelCarrousel = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

// #region GraphQL
export const query = graphql`
  {
    allListaMontalbanoYaml {
      edges {
        node {
          nombre
          orgsrc
          dscrpt          
          origen {
            childImageSharp {
              fixed(height: 100) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allAnecdotasCasaMontalbanoYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const AnecdotasCasaMontalbano = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFixed = nombre => {
    const edges = data.allAnecdotasCasaMontalbanoYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fixed = found.node.origen.childImageSharp.fixed
    return fixed
  }
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allAnecdotasCasaMontalbanoYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Relatos>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('img01casamontalbano1jpg')}
                title="La casa de Il Commissario Montalbano. Punta Secca. Sicilia."
                leyenda="La casa de 'Il Commissario Montalbano'. Punta Secca. Sicilia."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Fan de Montalbano</h2>
                <p>
                  Debo confesar que me encuentro entre los millones de fans de la serie de la RAI
                  "Il commissario Montalbano".
                </p>
                <p>
                  Así que, encontrándome en Sicilia, cómo no me iba a dar una vueltita por los
                  lugares donde se la filma ¡Qué emoción sentí al llegar a la casa de Montalbano! Se
                  encuentra en Punta Secca, que en la serie se llama Marinella ¡La casa en la playa!
                  ¡Qué linda que es!
                </p>
                <p>
                  Parece ser que la casa funciona como bed and breakfast, aunque no tiene ninguna
                  indicación, por lo menos a la vista, de este otro uso. Lo que sé, es que está muy
                  bien mantenida.
                </p>
                <p>
                  Fue un larguísimo viaje… Anduve perdida en el auto y dando vueltas en redondo por
                  horas. El hecho de que yo siempre ande perdida manejando será tema de otros
                  relatos!!!
                </p>
                <p>
                  Literalmente perdí horas ¡Llegué cuando atardecía! Así que me quedé sin ir a
                  Ragusa, que en la «RAI fiction» se llama Montelusa ¡Tengo que volver!
                </p>
                <p>
                  Porqué será que siempre que conozco un lugar en Italia termino diciéndome "¡tengo
                  que volver!"
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>Llegando a Punta Secca en el auto.</h3>
                <p>
                  Estaciono el auto exhausta. Bajo. Veo unas señoras y les pregunto por la casa de
                  Montalbano y me dicen que estaba justo allí, en la esquina, a unos metros y … la
                  ví!!!
                </p>
                <p>
                  Así que, a caminar esos pocos metros que me separaban de mi objetivo. Y lo primero
                  que vi es una entrada a la casa que no se ve en la serie. Se accede por una
                  escalera externa.
                </p>
                <p>
                  En la foto se ve en primer lugar la escalera, que mencioné, que pertenece a la
                  casa de «El Commissario Montalbano». Y al fondo un quiosco, detrás del cuál estaba
                  estacionado mi auto ¿Lo alcanzas a ver? Un Fiat Panda blanco.
                </p>
                En la siguiente foto te muestro, desde un ángulo desconocido, la casa iluminada, era
                casi de noche.
                <p>
                  Por donde se la mire es hermosa. Da gusto ver que está impecable. Di la vuelta
                  entorno para verla desde los tres lados libres.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('img02puntaseccapandajpg')}
                title="Mi Fiat Panda estacionado detrás del kiosco. Punta Secca. Sicilia."
                leyenda="Mi Fiat Panda estacionado detrás del kiosco. Punta Secca. Sicilia."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('img03casamontalbano3jpg')}
                title="La casa de Il Commissario Montalbano. Punta Secca. Sicilia."
                leyenda="La casa de Il Commissario Montalbano. Punta Secca. Sicilia."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>Recorriendo los alrededores.</h3>
                <p>
                  Puedes apreciar la entrada en lo alto de la escalera y el mar crecido llegando a
                  la terraza del frente.
                </p>
                <h3> ¿Cómo son los alrededores de la casa? </h3>
                <p>
                  Del otro lado del «estacionamiento donde el comisario deja su auto» (que no es tal
                  en la realidad), hay una torre y en la base una confitería con mesitas en una
                  terraza sobre el mar. Puedes verla en la siguiente foto.
                </p>
                <p>
                  Recorrí todos los alrededores de la casa para conocer algo de Punta Secca, que me
                  resultó un lugar encantador. Pequeño y muy prolijo.
                </p>
                <p>
                  También vi el faro, el que se muestra en la vista aérea al principio de la serie.
                  Y la pequeña marina.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  Agregué más fotos para que puedas darte una idea de cómo es este encantador
                  pueblito y también para que puedas ver detalles de la casa y vistas desde otros
                  ángulos. Espero que no te parezca que soy demasiado fanática ¿o lo seré realmente?
                </p>
                <p>
                  Hay vistas de la casa desde la playa, ya que di la vuelta alrededor ¡Tanto me
                  gustó! También fotos de la marina, del faro, en fin, fotos como para puedas
                  imaginarte la casa en su contexto.
                </p>
                <h3> Pero… ¿Qué ocurre? </h3>
                <p>
                  ¡Se encienden las luces! Cae el sol… ¡Y yo que tenía que seguir manejando hasta mi
                  próximo destino y no quería llegar de noche! Ni un café pude tomar en este lugar.
                  A seguir viaje y a rogar que no me pierda… ¿Crees que lo logré?
                </p>
                <p>
                  Te adelanto el final. Fue inútil. Me perdí. Llegué de noche y tan tarde que me
                  quedé sin cenar!!!! Será motivo de otro relato.
                </p>
                <p> Sucedió un 25 de marzo de 2017. </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('img04torrepuntaseccajpg')}
                title="Torre enfrente de la casa de Montalbano. Punta Secca. Sicilia."
                leyenda="Torre enfrente de la casa de Montalbano. Punta Secca. Sicilia."
              />
            </Minipagina>
          </Minipaginas>
          <EnvoltorioDelCarrousel>
            <TituloDelCarrousel>...aquí tienes más fotos...</TituloDelCarrousel>
            <FotosYCarrousel edges={data.allListaMontalbanoYaml.edges} />
          </EnvoltorioDelCarrousel>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Relatos>
    </Marco>
  )
}
export default AnecdotasCasaMontalbano
