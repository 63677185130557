import React from 'react'
import { css } from '@emotion/core'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

const flechaizq = css`
  padding-left: 1rem;
`
const flechader = css`
  padding-right: 1rem;
`
const linkizq = css`
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
    opacity: 0.6;
  }
`
const linkder = css`
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: inherit;
    opacity: 0.6;
  }
`
const espaciovertical = css`
  padding-top: 1rem;
`
const Navegar = ({ location }) => {
  console.log(location)
  const data = useStaticQuery(graphql`
    {
      allArticulosYaml {
        edges {
          node {
            articulo
            tipo
            titulo
            fecha
          }
        }
      }
    }
  `)
  const lista = data.allArticulosYaml.edges
    .map(x => x.node)
    .sort(function(a, b) {
      if (a.fecha > b.fecha) {
        return 1
      }
      if (a.fecha < b.fecha) {
        return -1
      }
      // a must be equal to b
      return 0
    })
  console.log(lista)
  const articuloActual = location.pathname.replace(/\//g, '')
  const indice = lista.findIndex(x => x.articulo === articuloActual)
  const anterior = indice === 0 ? 0 : indice - 1
  const posterior = indice === lista.length - 1 ? lista.length - 1 : indice + 1
  const urlAnterior = '/' + lista[anterior].articulo + '/'
  const urlPosterior = '/' + lista[posterior].articulo + '/'
  const tituloAnterior = lista[anterior].titulo
  const tituloPosterior = lista[posterior].titulo

  return (
    <Minipaginas>
      <Minipagina>
        <div css={flechaizq}>
          <Link css={linkizq} to={urlAnterior}>
            <div>
              <ChevronLeftIcon style={{ fontSize: 64 }} />
            </div>
            <div css={espaciovertical}>
              <div>Anterior</div>
              <div>{tituloAnterior}</div>
            </div>
          </Link>
        </div>
      </Minipagina>
      <Minipagina>
        <div css={flechader}>
          <Link css={linkder} to={urlPosterior}>
            <div css={espaciovertical}>
              <div>Posterior</div>
              <div>{tituloPosterior}</div>
            </div>
            <div>
              <ChevronRightIcon style={{ fontSize: 64 }} />
            </div>
          </Link>
        </div>
      </Minipagina>
    </Minipaginas>
  )
}

export default Navegar
