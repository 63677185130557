import React                from 'react'
import Collapse             from '@material-ui/core/Collapse'
import Minipaginas          from './minipaginas'
import Minipagina           from './minipagina'
import TextoEnMinipagina    from './texto-en-minipagina'
import EspaciadorVertical   from './espaciador-vertical'
import {mensajesPorTopic}   from '../utiles/da'
import FormularioDeContacto from './formulario-de-contacto'
import ListaDeComentarios   from './lista-de-comentarios'
import Button               from '@material-ui/core/Button'
import AutorenewIcon        from '@material-ui/icons/AutorenewTwoTone'
import styled               from '@emotion/styled'
import {FontAwesomeIcon}    from '@fortawesome/react-fontawesome'
import {faComments}         from '@fortawesome/free-solid-svg-icons'
import {css}                from '@emotion/core'
import {pipe, split, last, sortBy, reduce, max, compose, negate, propEq, either} from 'ramda'

const ANCHO_SANGRIA         = 36
const ANCHO_PRESENTACION    = 36
const Presentacion          = styled.div`width:48px;padding-top:2px;`
const Contenido             = styled.div`color:#303030;width:100%;border-bottom: 1px solid #00000040`
const AccionesALaIzquierda  = styled.div`width: 50%`
const AccionesALaDerecha    = styled.div`width: 50%; text-align: right`
const LetraChica            = styled.div`padding-top:2px;font-size:1.3rem;`
const cssavatar             = css`font-size:16px;color:#74a7be;margin-top:8px;margin-left:3px;` // #16455a
const cssacciones           = css`margin-bottom: 4px;margin-top:4px; display:flex`

const ultimaFecha           = c => max(new Date(c.estampado).getTime(), reduce((m, r) => max(m, ultimaFecha(r)), 0)(c.respuestas || []) )
const clasificarLista       = sortBy(compose(negate, ultimaFecha))
const eLEstadoEs            = propEq('estado')
const noCuenta              = either(eLEstadoEs('pendiente'), eLEstadoEs('borrado'))
const totalDeComentarios    = reduce((total, c) => total + totalDeComentarios(c.respuestas || []) + (noCuenta(c) ? 0 : 1), 0)

const ContenedorDelComentario = styled.div(props => {
  return ({
    paddingLeft: ANCHO_SANGRIA * props.nivel,
    paddingRight: 18,
    marginTop: 24,
    color: 'black',
    display: 'grid',
    gridTemplateColumns: `${ANCHO_PRESENTACION}px auto`,
  })
})

const vengaLaLista = async queHacerCon => {
  const topic = pipe(split('/'), last)(window.location.href)
  mensajesPorTopic(topic).then(lalista => queHacerCon(lalista))
}

const Comentarios = ({masPalidos}) => {
  // hooks
  const [lista, setLista] = React.useState([])
  const [checked, setChecked] = React.useState(false)
  React.useEffect(() => {
    vengaLaLista(laLista => setLista(clasificarLista(laLista)))
  }, [])
  const handleRefresh = () => {
    vengaLaLista(laLista => setLista(clasificarLista(laLista)))
  }
  const handleChange = () => {
    setChecked(prev => !prev)
  }
  const handleClose = () => setChecked(false)
  const hay = totalDeComentarios(lista)
  const textoDeCuantasHay =   !hay ? 'Todavía no hay ningún comentario sobre este artículo (te están esperando)'
    : `Hay ${hay === 1 ? 'un' : hay} comentario${hay !== 1 ? 's' : ''} sobre este artículo. `

  return <Minipaginas>
    <Minipagina todoElAncho>
      <TextoEnMinipagina>
        <TextoEnMinipagina palido repalido={masPalidos} >
          <EspaciadorVertical altura={36} />

          {/* "comentario" virtual al iniciar la recursión */}
          <ContenedorDelComentario nivel={0}>
            <Presentacion>
              <FontAwesomeIcon icon={faComments} css={cssavatar} />
            </Presentacion>
            <Contenido>
              <div css={cssacciones}>
                <AccionesALaIzquierda>
                  <LetraChica>
                    {textoDeCuantasHay}
                    <Button color="primary" onClick={handleRefresh}><AutorenewIcon /> </Button>
                  </LetraChica>
                </AccionesALaIzquierda>
                <AccionesALaDerecha>
                  <Button variant="contained" color="primary" onClick={handleChange}>Deja tu comentario</Button>
                </AccionesALaDerecha>
              </div>
              <Collapse in={checked} mountOnEnter unmountOnExit>
                <div>
                  <FormularioDeContacto comentario handleClose={handleClose} />
                </div>
              </Collapse>
            </Contenido>
          </ContenedorDelComentario>

          <ListaDeComentarios lista={lista} />
          <EspaciadorVertical altura={24} />
        </TextoEnMinipagina>
      </TextoEnMinipagina>
    </Minipagina>
  </Minipaginas>
}

export default Comentarios
